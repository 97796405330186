import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HomePage from '../views/HomePage.vue'

Vue.use(VueRouter)

const flag = navigator.userAgent.match(
	/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);

const routes = [{
		path: '/',
		name: 'home',
		component: flag ? HomePage : HomeView,
	},
	{
		path: '/aptitudeHonor',
		name: 'aptitudeHonor',
		component: () => flag ? import('../views/AptitudeHonorPage.vue') : import('../views/AptitudeHonorView.vue')
	},
	{
		path: '/businessArea',
		name: 'businessArea',
		component: () => flag ? import('../views/BusinessAreaPage.vue') : import('../views/BusinessAreaView.vue'),
	},
	{
		path: '/performance',
		name: 'performance',
		component: () => import('../views/PerformanceView.vue')
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => flag ? import('../views/ContactPage.vue') : import('../views/ContactView.vue')
	}, {
		path: '/message',
		name: 'message',
		component: () => flag ? import('../views/MessagePage.vue') : import('../views/MessageView.vue')
	}, {
		path: '/newsFeed',
		name: 'newsFeed',
		component: () => import('../views/NewsFeedView.vue')
	}, {
		path: '/recruitment',
		name: 'recruitment',
		component: () => import('../views/RecruitmentView.vue')
	},
	{
		path: '/search',
		name: 'search',
		component: () => flag ? import('../views/SearchPage.vue') : import('../views/SearchView.vue')
	},
	{
		path: '/businessArea/1',
		name: 'businessArea1',
		component: () => flag ? import('../views/BusinessAreaPage/1.vue') : import('../views/BusinessArea/1.vue')
	},
	{
		path: '/businessArea/2',
		name: 'businessArea2',
		component: () => flag ? import('../views/BusinessAreaPage/2.vue') : import('../views/BusinessArea/2.vue')
	},
	{
		path: '/businessArea/3',
		name: 'businessArea3',
		component: () => flag ? import('../views/BusinessAreaPage/3.vue') : import('../views/BusinessArea/3.vue')
	},
	{
		path: '/businessArea/4',
		name: 'businessArea4',
		component: () => flag ? import('../views/BusinessAreaPage/4.vue') : import('../views/BusinessArea/4.vue')
	},
	{
		path: '/businessArea/5',
		name: 'businessArea5',
		component: () => flag ? import('../views/BusinessAreaPage/5.vue') : import('../views/BusinessArea/5.vue')
	},
	{
		path: '/businessArea/6',
		name: 'businessArea6',
		component: () => flag ? import('../views/BusinessAreaPage/6.vue') : import('../views/BusinessArea/6.vue')
	},
	{
		path: '/businessArea/7',
		name: 'businessArea7',
		component: () => flag ? import('../views/BusinessAreaPage/7.vue') : import('../views/BusinessArea/7.vue')
	},
	{
		path: '/businessArea/8',
		name: 'businessArea8',
		component: () => flag ? import('../views/BusinessAreaPage/8.vue') : import('../views/BusinessArea/8.vue')
	},
	{
		path: '/businessArea/9',
		name: 'businessArea9',
		component: () => flag ? import('../views/BusinessAreaPage/9.vue') : import('../views/BusinessArea/9.vue')
	},
	{
		path: '/businessArea/10',
		name: 'businessArea10',
		component: () => flag ? import('../views/BusinessAreaPage/10.vue') : import('../views/BusinessArea/10.vue')
	}
]


const router = new VueRouter({
	routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const originalPush2 = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
	return originalPush2.call(this, location).catch(err => err)
}

export default router
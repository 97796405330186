import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		BusinessArr: [{
			img: "@/assets/businessArea-list-img1.png",
			title: '智能火车采样机系列',
			path: "/businessArea/1",
		}, {
			img: "@/assets/businessArea-list-img2.png",
			title: '智能汽车采样机系列',
			path: "/businessArea/3",
		}, {
			img: "@/assets/businessArea-list-img3.png",
			title: '智能皮带采制样机系列',
			path: "/businessArea/10",
		}, {
			img: "@/assets/businessArea-list-img4.png",
			title: '智能型样品封装系统',
			path: "/businessArea/10",
		}, {
			img: "@/assets/businessArea-list-img5.png",
			title: '清算破碎机系列',
			path: "/businessArea/8",
		}, {
			img: "@/assets/businessArea-list-img6.png",
			title: '给料式破碎机系列',
			path: "/businessArea/7",
		}, {
			img: "@/assets/businessArea-list-img7.png",
			title: '双轴式混合机系列',
			path: "/businessArea/6",
		}, {
			img: "@/assets/businessArea-list-img8.png",
			title: '叶轮给煤机（双侧式叶轮给煤机）系列',
			path: "/businessArea/6",
		}, {
			img: "@/assets/businessArea-list-img9.png",
			title: '定量叶轮给料（焦） 机系列',
			path: "/businessArea/2",
		}, {
			img: "@/assets/businessArea-list-img10.png",
			title: '板式给矿机',
			path: "/businessArea/2",
		}, {
			img: "@/assets/businessArea-list-img11.png",
			title: '重型板式给料机',
			path: "/businessArea/2",
		}, {
			img: "@/assets/businessArea-list-img12.png",
			title: '定量圆盘给料系统',
			path: "/businessArea/5",
		}, {
			img: "@/assets/businessArea-list-img13.png",
			title: '智能松动机系列',
			path: "/businessArea/4",
		}, {
			img: "@/assets/businessArea-list-img14.png",
			title: '智能制样机系列',
			path: "/businessArea/8",
		}, {
			img: "@/assets/businessArea-list-img15.png",
			title: '螺旋卸车机系列',
			path: "/businessArea/7",
		}],
	},
	getters: {},
	mutations: {},
	actions: {},
	modules: {}
})